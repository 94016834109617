import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import { graphYAxisData, graphXAxisData } from '../../helpers/utility';
import { StyleText } from '../../pages/Admin/StudentSummary';

const LineChart = () => {
  const data = useSelector((state) => state.admin.subscriptionsGraph);

  const option = {
    title: {
      text: 'Subscription Line Chart',
    },
    grid: { top: 40, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: 'category',
      data: graphXAxisData(data),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: graphYAxisData(data),
        type: 'line',
        smooth: true,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  };

  return (
    <>
      {/* <ReactECharts option={{option}} /> */}
      <ReactECharts option={{}} />
      <StyleText>Coming Soon</StyleText>
    </>
  );
};

export default LineChart;
