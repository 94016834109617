import Inform1 from '../../assets/images/HomePage/InformationImages/homeInform1.png';
import Inform2 from '../../assets/images/HomePage/InformationImages/homeInform2.png';
import Inform3 from '../../assets/images/HomePage/InformationImages/homeInform3.png';
import Inform4 from '../../assets/images/HomePage/InformationImages/homeInform4.png';

const InformData = [
  {
    image: Inform1,
    text: 'Neuroplaticity of the brain',
    subText: 'YES! YES! YES! THE BRAIN CHANGES AND ADAPTS',
  },
  {
    image: Inform2,
    text: 'Eye tracking and focus',
    subText: 'IMPROVE FOCUS AND TRACKING ABILITY',
  },
  {
    image: Inform3,
    text: 'Teaching strategies',
    subText: 'CHUNKING AND SCAFFOLDING',
  },
  {
    image: Inform4,
    text: 'Full body full brain movement',
    subText: 'FUN, MUSCULAR MEMORY, AND LEARNING',
  },
];

export { InformData };
