import React from 'react';
import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import { graphYAxisData, graphXAxisData } from '../../helpers/utility';
import { StyleText } from '../../pages/Admin/StudentSummary';

const BarChart = () => {
  const data = useSelector((state) => state.admin.subscriptionsGraph);

  const option = {
    title: {
      text: 'Subscription Bar Chart',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: graphXAxisData(data) && graphXAxisData(data),
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Subscriber',
        type: 'bar',
        barWidth: '60%',
        data: graphYAxisData(data) && graphYAxisData(data),
      },
    ],
  };

  return (
    <>
      {/* <ReactECharts option={option} /> */}
      <ReactECharts option={{}} />
      <StyleText>Coming Soon</StyleText>
    </>
  );
};

export default BarChart;
